import React, { useState } from 'react';
import '../css/PriceList.css';
import bannerImg from '../images/opt-151230001-salted-salmon-or-trout-sea-fis.webp'
import fishmongeringImg from '../images/fishmongering_1.jpg'
import fishmongeringImg2 from '../images/fishmongering_2.jpg'
import commercialFishing from '../images/commercial_fishing_1.jpg'
import commercialFishing2 from '../images/commercial_fishing_2.jpg'
import crewpackImg from '../images/our_market_crewpack.jpg'
import Recommends from './Recommends';
import SignUpButton from './SignUpButtom';
import PopUp from './PopUp';
import FeatureBanner from './FeatureBanner';
import { Link } from 'react-router-dom';


const PriceList = () => {


  return (
    <div className="price-list">
      <div className="price-list-span" style={{ backgroundImage: 'url(' + bannerImg + ')' }}>
        <div className="price-list-span-wrapper">
          <h2 className="price-list-title">Our Markets</h2>
          <h3>From our crews on the boats, to our crews at the markets.</h3>
          <h4>Quality You Can Trust</h4>
        </div>
      </div>

      <div className="price-list-wrapper">
        <div className="price-list-content">
          <div className="store-price">
            <div className="store-price-class">
              <div className="class-item">
                <h3>From the showcase</h3>
                <h4>Fresh Cut Seafood and Live Shellfish</h4>
                <div className="class-item-list">
                  <ul>
                    <li>
                      <span className="class-item-label">Halibut Fillets</span>
                    </li>
                    <li>
                      <span className="class-item-label">Ling Cod Fillets</span>
                    </li>
                    <li>
                      <span className="class-item-label">Sablefish Fillets</span>
                    </li>
                    <li>
                      <span className="class-item-label">Rockfish Fillets</span>
                    </li>
                    <li>
                      <span className="class-item-label">Icealndic Cod Fillet</span>
                    </li>
                    <li>
                      <span className="class-item-label">Halibut Cheeks</span>
                    </li>
                    <li>
                      <span className="class-item-label">Live Mussels</span>
                    </li>
                    <li>
                      <span className="class-item-label">Live Oysters</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span className="class-item-label">Sockeye Fillets</span>
                    </li>
                    <li>
                      <span className="class-item-label">Spring Fillets</span>
                    </li>
                    <li>
                      <span className="class-item-label">Coho Fillets</span>
                    </li>
                    <li>
                      <span className="class-item-label">Steelhead Fillets</span>
                    </li>
                    <li>
                      <span className="class-item-label">Char Fillets</span>
                    </li>
                    <li>
                      <span className="class-item-label">Ahi tuna steaks</span>
                    </li>
                    <li>
                      <span className="class-item-label">Live Clams</span>
                    </li>
                    <li>
                      <span className="class-item-label">Live Premium Oysters</span>
                    </li>
                  </ul>
                </div>
                  <Link to="/locations" className="class-item-link">CHECK IN STORE FOR MARKET PRICING</Link>
              </div>
              <div className="class-item">
                <h3>FISH MONGERING</h3>
                <div className="tile-layout">
                  <div className="tile-image">
                    <img src={fishmongeringImg} ></img>
                  </div>
                  <div className="tile-text">
                    <p>Our crews arrive early every morning to hand cut a majority of the fish that goes into our showcase, this skill allows us to ensure the quality of every fish that makes it onto your dinner table</p>
                  </div>
                  <div className="tile-text">
                    <p>Being termed a fishmonger is more than a learned trade, it's a term of respect in the fishing industry indicating not only your ability but also your commitments to sustainability, your community and the quality of your product.</p>
                  </div>
                  <div className="tile-image">
                    <img src={fishmongeringImg2} ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeatureBanner
        title="MONTHLY FEATURE"
        productName="West Coast Shellfish"
        description="Savour the West Coast's Finest Shellfish! including clams, mussels, and oysters. It's the perfect season to experience the exceptional quality the West Coast has to offer. Don't miss out on these fresh, flavourful options!"
        desWidth={"650px"}
        bgImage="/images/ourmarket_segment_banner_shellfish.webp"
        location="flex-start"
        linkTo="locations"
        buttonText={"SHOP NOW IN STORE"}
      />
      <div className="price-list-wrapper">
        <div className="price-list-content">
          <div className="store-price">
            <div className="store-price-class">
              <div className="class-item">
                <h3>COMMERCIAL FISHING</h3>
                <div className="tile-layout">
                  <div className="tile-image">
                    <img src={commercialFishing} ></img>
                  </div>
                  <div className="tile-text">
                    <p>Fun Fact: Many of the species we fish on the west coast are frozen directly on the boats. This is called Frozen At Sea (FAS). These whole cleaned fish are then shipped to our markets where they are thawed every morning and “Fresh Cut” for the showcase. This allows us to offer top quality, BC seafood all year round.</p>
                  </div>
                  <div className="tile-text">
                    <p>Fisherman’s Market boasts several of our own fishing vessels as well as affiliated boats that specialize in everything from spot prawns, salmon, lingcod, halibut, tuna and sablefish. We are also still active fisherman ourselves and go out on the boats annually.</p>
                  </div>
                  <div className="tile-image">
                    <img src={commercialFishing2} ></img>
                  </div>
                </div>
              </div>
              <div className="class-item">
                <h3>From the showcase to the shelves</h3>
                <h4>Smoked, Value Added, and Dry Goods</h4>
                <div className="class-item-list">
                <ul>
                  <li>
                    <span className="class-item-label">Candy Smoked Salmons</span>
                  </li>
                  <li>
                    <span className="class-item-label">Smoked Spreads and Pates</span>
                  </li>
                  <li>
                    <span className="class-item-label">Candy Smoked Sablefish</span>
                  </li>
                  <li>
                    <span className="class-item-label">Smoked Salmon Lox</span>
                  </li>
                  <li>
                    <span className="class-item-label">Tuna Pot Pies</span>
                  </li>
                  <li>
                    <span className="class-item-label">Sockeye Phyllo Wraps</span>
                  </li>
                  <li>
                    <span className="class-item-label">Smoked Squid and Seaweed Salads</span>
                  </li>
                  <li>
                    <span className="class-item-label">Ahi Tuna Poke</span>
                  </li>
                </ul>
                <ul>
                  <li>
                    <span className="class-item-label">Canned Clams and Nectars</span>
                  </li>
                  <li>
                    <span className="class-item-label">Cocktail and Tartar Sauces</span>
                  </li>
                  <li>
                    <span className="class-item-label">Batters and Breading</span>
                  </li>
                  <li>
                    <span className="class-item-label">Spices and Rubs</span>
                  </li>
                  <li>
                    <span className="class-item-label">Seafood Pet Treats</span>
                  </li>
                
                </ul>
                </div>
                <h4>Our markets are filled with everything you need to make an amazing seafood meal.</h4>
              </div>
              <div className="class-item" style={{ backgroundColor: "#FAFAF5", padding: "30px 0" }}>
                <div className="tile-layout" style={{alignItems: "flex-start"}}>
                  <div className="tile-image" style={{ height: "unset", width: "350px", placeSelf: "center" }}>
                    <img src={crewpackImg} style={{ objectFit: "contain" }}></img>
                  </div>
                  <div className="tile-text" style={{fontSize: "1.125rem", lineHeight: "2"}}>
                    <h3 style={{textAlign: "left", marginLeft: "0"}}>CREW PACKS</h3>
                    <p style={{textAlign: "left"}}>Fisherman's Market Crew Packs are a healthy, ethical, and money-conscious way to get more seafood into your diet. These packs are filled with specially sourced fish that are carefully selected, properly frozen, and vacuum-sealed into convenient portions. Designed to fill your freezer and save you money, our Crew Packs contain a bulk amount of a specific species of fish, ensuring you always have high-quality, responsibly sourced seafood on hand. Enjoy delicious and nutritious meals with the assurance that you're making a sustainable and cost-effective choice.</p>
                  </div>
                </div>
                <Link to="/shop" className="shop-now" style={{textAlign: "center", margin: "20px auto"}}>SHOP NOW</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Recommends />
      <SignUpButton />
      <PopUp/>

    </div>
  );
}

export default PriceList;
