import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Contest.css';

const Contest = () => {
    return (
        <div>
            <div className='contest-wrapper'>
                <div className='contest-info'>
                    <div className='contest-banner-image' style={{ backgroundImage: 'url(./images/contest_banner_bg.png)' }}></div>
                    <div className='contest-content'>
                        <h2 className="contest-title">Fisherman's Market</h2>
                        <h3 className="contest-subtitle">Your Trusted leader in BC Seafood</h3>
                        <p style={{ lineHeight: '1.5' }}>Get ready to reel in some savings! We're giving away <b><em>two $250 gift cards—one for our Nelson location and one for our Kelowna location.</em></b> That's right, two lucky winners will have the chance to stock up on the freshest seafood around. Enter now for your chance to win and enjoy the best that <a className="contest-link" href='https://thefishermansmarket.ca'>The Fisherman's Market</a> has to offer!</p>
                    </div>
                </div>
                <div className='contest-form'>
                    <h1 style={{ textAlign: 'center' }}><img height='120' src='./logo192.png' /></h1>
                    <div style={{margin: '2rem 0 2rem'}}>
                        <p style={{ margin: '.25rem .5rem' }}>Contest ends on October 1, 2024.</p>
                        <p style={{ margin: '.25rem .5rem' }}>Enter once per day.</p>
                        <p style={{ margin: '.25rem .5rem' }}>For full list of conditions and details click on rules at the bottom of the page.</p>
                    </div>
                    <div className='contest-input'>
                        <input className='contest-input-item' type="text" placeholder="Full Name" />
                        <select className='contest-input-item' >
                            <option value="">Select a store near you</option>
                            <option value="Nelson">Nelson</option>
                            <option value="Gibsons">Gibsons</option>
                            <option value="Kelowna">Kelowna</option>
                            <option value="Kamloops">Kamloops</option>
                            <option value="West Vancouver">West Vancouver</option>
                            <option value="Burnaby">Burnaby</option>
                            <option value="New Denver">New Denver</option>
                        </select>
                        <input className='contest-input-item' type="text" placeholder="Email" />
                    </div>
                    <button className='contest-button'>SUBMIT</button>
                    <div className='contest-rules'>
                        <p>By clicking the submit button below, I agree to the <Link to='/terms-conditions'>Terms of Service</Link> and <Link to='/privacy-policy'>Privacy Policy</Link></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contest;
