import { React } from 'react';
import { Link } from 'react-router-dom';
import '../css/TgBanner.css';

const TgBanner = () => {
    return (
        <div className="tg-banner">
            <div className="tg-banner-wrapper">
                <div className="tg-banner-content">
                    <h1 className="tg-banner-title">Add Seafood to Your Thanksgiving Feast</h1>
                    <h2 className="tg-banner-product-name">Enjoy 15% Off</h2>
                    <p className="tg-banner-description">(On Non-Sale ltems)</p>
                    <p className="tg-banner-date">October 8-13</p>
                    <div className="tg-banner-button-content">
                        <Link to="/shop" className="tg-banner-button">SHOP ONLINE</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TgBanner;